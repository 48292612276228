// All user related database operations can be defined here.

import {
  GET_ALL_DISPLAY_OFFERS,
  GET_CASHBACK_OFFERS,
  GET_DISPLAY_OFFER_BY_URL,
  TRACK_DISPLAY_OFFER_CLICK,
} from "./CONSTANTS";
import http from "../http-common";

class DisplayOffersDataService {
  getAll() {
    return http.get(GET_ALL_DISPLAY_OFFERS);
  }

  get(url: string) {
    return http.get(GET_DISPLAY_OFFER_BY_URL(url));
  }

  getCashbackOffers(station: number) {
    return http.get(GET_CASHBACK_OFFERS(station));
  }

  track(url: string, category: string, user: any, visitId: string) {
    return http.post(
      TRACK_DISPLAY_OFFER_CLICK(url),
      { category: category, visitId: visitId },
      {
        headers: {
          Authorization: user.type + " " + user.token,
        },
      }
    );
  }
}

export default new DisplayOffersDataService();
