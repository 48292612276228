import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  getNotification,
  clearNotification,
} from "../../../slices/notifications";
import { Alert, Button, Snackbar } from "@mui/material";
import { useAuth } from "../../../navigation/Authentication";

export const NotificationCentre = (props: any) => {
  const { user } = useAuth();
  const notificationActive = useRef(false);

  useEffect(() => {
    let interval = 0;
    let cleanup = true;

    if (!user) return;

    if (!notificationActive.current) {
      props.getNotification(user);

      interval = setInterval(() => {
        if (props.notification == null) {
          props.getNotification(user);
        }
      }, 10000);

      notificationActive.current = true;
      cleanup = false;
    }

    return () => {
      if (cleanup) {
        clearInterval(interval);
      }
    };
  }, []);

  if (!props.notification) {
    return <></>;
  }
  return (
    <Snackbar
      open={true}
      color="success"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert severity={"success"}>
        {props.notification.message}
        <Button
          onClick={() => {
            props.clearNotification();
          }}
        >
          DISMISS
        </Button>
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (state: any) => {
  return {
    notification: state.notifications.notification,
  };
};

export default connect(mapStateToProps, {
  getNotification,
  clearNotification,
})(NotificationCentre);
