// All user related database operations can be defined here.

import { GET_DOCUMENT } from "./CONSTANTS";
import http from "../http-common";

class DocumentsDataService {
  get(type: string) {
    return http.get(GET_DOCUMENT(type));
  }
}

export default new DocumentsDataService();
