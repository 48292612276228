import { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../navigation/Authentication";
import { styled } from "@mui/material";

export const HeaderMenu = ({}: any) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const goToMyAccount = (e: any) => {
    e.preventDefault();
    setOpen(false);
    navigate("/my-account");
  };

  const goToTasks = (e: any) => {
    e.preventDefault();
    setOpen(false);
    navigate("/stations");
  };

  const goToAbout = (e: any) => {
    e.preventDefault();
    setOpen(false);
    navigate("/about");
  };

  const goToPaymentDetails = (e: any) => {
    e.preventDefault();
    setOpen(false);
    navigate("/payment-details");
  };

  const goToMyCashback = (e: any) => {
    e.preventDefault();
    setOpen(false);
    navigate("/my-cashback");
  };

  const goToSignup = (e: any) => {
    e.preventDefault();
    setOpen(false);
    navigate("/register");
  };

  const goToLogin = (e: any) => {
    e.preventDefault();
    setOpen(false);
    navigate("/login");
  };

  const doLogout = (e: any) => {
    e.preventDefault();
    setOpen(false);
    logout();
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" style={{ float: "right" }} spacing={2}>
      <div style={{ zIndex: 5000 }}>
        <MenuButton
          variant="contained"
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {user && <>...</>}
          {!user && <>...</>}
        </MenuButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <MenuListContainer>
                <>
                  {user && (
                    <ClickAwayListener onClickAway={handleClose}>
                      <Menu
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem href="/stations" onClick={goToTasks}>
                          {" "}
                          Tasks
                        </MenuItem>
                        <MenuItem href="/my-account" onClick={goToMyAccount}>
                          My account
                        </MenuItem>
                        <MenuItem href="/my-cashback" onClick={goToMyCashback}>
                          My Cashback
                        </MenuItem>
                        <MenuItem
                          href="payment-details"
                          onClick={goToPaymentDetails}
                        >
                          Payment Details
                        </MenuItem>
                        <MenuItem href="/about" onClick={goToAbout}>
                          About
                        </MenuItem>
                        <MenuItem
                          href="https://cashbacktrain.freshdesk.com/support/home"
                          target={"_blank"}
                        >
                          Help
                        </MenuItem>
                        <MenuItem onClick={doLogout}>Logout</MenuItem>
                      </Menu>
                    </ClickAwayListener>
                  )}
                  {!user && (
                    <ClickAwayListener onClickAway={handleClose}>
                      <Menu
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={goToSignup}>Signup</MenuItem>
                        <MenuItem onClick={goToLogin}>Login</MenuItem>
                      </Menu>
                    </ClickAwayListener>
                  )}
                </>
              </MenuListContainer>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
};

const MenuButton = styled(Button)(() => ({
  backgroundColor: "#ffcc21",
  borderRadius: "8px",
  color: "#333",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#ffcc21",
    color: "#333",
  },
}));

const Menu = styled(MenuList)(() => ({
  backgroundColor: "#fff",
  border: "solid 1px #333",
  borderRadius: "10px 10px",
  color: "#333",
  fontWeight: 600,
}));

const MenuListContainer = styled("div")(() => ({
  textAlign: "center",
  minWidth: "110px",
  paddingLeft: "-10px",
}));
