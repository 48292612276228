declare var sendinblue: any;

export const useSendInBlue = (): any => {
  const identify = (user: any) => {
    if (user == null) return;

    setTimeout(() => {
      sendinblue.identify(user.userData.emailAddress, {
        FirstName: user.userData.firstName,
        LastName: user.userData.lastName,
        Id: user.userData.id,
      });
    }, 2000);
  };

  const track = (eventName: string, event: any) => {
    setTimeout(() => {
      sendinblue.track(eventName, event);
    }, 2000);
  };

  const pageView = () => {
    setTimeout(() => {
      sendinblue.page(document.title);
    }, 2000);
  };

  return { identify, track, pageView };
};
