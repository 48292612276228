// All user related database operations can be defined here.

import { GET_NOTIFICATION } from "./CONSTANTS";
import http from "../http-common";

class NotificationsDataService {
  getNotification(user: any) {
    return http.get(GET_NOTIFICATION, {
      headers: {
        Authorization: user.type + " " + user.token,
      },
    });
  }
}

export default new NotificationsDataService();
