import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import notificationsService from "../services/notificationsService";

export interface notification {
  message: string;
}

interface notifications$State {
  notification: notification | null;
}

export const initialState: notifications$State = {
  notification: null,
};

export const getNotification = createAsyncThunk(
  "notifications/getnotification",
  async (user: any) => {
    const res = await notificationsService.getNotification(user);
    return res.data;
  }
);

export const clearNotification = createAsyncThunk(
  "notifications/clearnotification",
  async () => {
    return true;
  }
);

const customTasksSlice = createSlice({
  name: "notifications",
  initialState,
  extraReducers: (builder) =>
    builder
      .addCase(getNotification.fulfilled, (state, action) => {
        return (state = {
          notification: action.payload,
        });
      })
      .addCase(getNotification.rejected, (state) => {
        return (state = {
          notification: null,
        });
      })
      .addCase(clearNotification.fulfilled, (state) => {
        return (state = {
          notification: null,
        });
      }),
  reducers: {},
});

const { reducer } = customTasksSlice;

export default reducer;
