import React from "react";
import PropTypes from "prop-types";
import { Grid, styled, Typography } from "@mui/material";
import logo from "../../assets/images/logo.webp";
import { Link } from "react-router-dom";

const Footer = (props: any) => (
  <FooterGrid
    container
    spacing={2}
    padding={"2rem 0 5rem 0"}
    marginTop={"2rem"}
    justifyContent={"center"}
  >
    <Grid item sm={6}>
      <LogoImage src={logo} width="300" height="54" alt="Cashback Train" />
    </Grid>
    <Grid container textAlign={"center"} marginTop={"3rem"} alignItems="center">
      <Grid item xs={3}>
        <Link to={"/register"}>Signup</Link>
      </Grid>
      <Grid item xs={3}>
        <Link to={"/documents/privacy-policy"}>Privacy policy</Link>
      </Grid>
      <Grid item xs={3}>
        <Link to={"/documents/terms-and-conditions"}>
          Terms &amp; Conditions
        </Link>
      </Grid>
      <Grid item xs={3}>
        <Link to={"/affiliates"}>Affiliates</Link>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Typography fontSize={"0.8em"} textAlign={"center"}>
        Copyright &copy; Cashback Train {new Date().getFullYear()}.
      </Typography>
    </Grid>
  </FooterGrid>
);

Footer.propTypes = {
  pageName: PropTypes.string,
};

export const FooterGrid = styled(Grid)`
  @media (min-width: 767px) {
    img {
      max-width: 300px;
    }
  }
`;

export const LogoImage = styled("img")`
  max-width: calc(100% - 2rem);
  position: relative;
  display: block;
  margin: 0 1rem;
`;

export default Footer;
