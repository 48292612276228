import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DocumentsService from "../services/documentsService";

interface documents$State {
  document: string | null;
  notFound: boolean;
}

export const initialState: documents$State = {
  document: null,
  notFound: false,
};

export const getDocument = createAsyncThunk(
  "documents/privacy",
  async (documentType: string, { rejectWithValue }) => {
    try {
      const res = await DocumentsService.get(documentType);
      return res.data;
    } catch (ex) {
      return rejectWithValue("not found");
    }
  }
);

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  extraReducers: (builder) =>
    builder
      .addCase(getDocument.fulfilled, (state, action) => {
        return (state = {
          document: action.payload,
          notFound: false,
        });
      })
      .addCase(getDocument.rejected, (state) => {
        return (state = {
          document: null,
          notFound: true,
        });
      })
      .addCase(getDocument.pending, (state) => {
        return (state = {
          document: null,
          notFound: false,
        });
      }),
  reducers: {},
});

const { reducer } = documentsSlice;

export default reducer;
