import Header from "./Header";
import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../navigation/Authentication";
import { useSendInBlue } from "../../tracking/sendinblue-tracker";
import { useEffect } from "react";
import Footer from "./Footer";

interface Layout$Props {
  children: any;
}

export const Layout = ({ children }: Layout$Props) => {
  const location = useLocation();
  const { user } = useAuth();
  const { identify } = useSendInBlue();
  const { pageView } = useSendInBlue();

  useEffect(() => {
    pageView();
  }, [location]);

  identify(user);

  return (
    <>
      <Container disableGutters={true} style={{ background: "#fff" }}>
        <Header />
        <main>{children}</main>
        <Footer />
      </Container>
    </>
  );
};
