import { configureStore } from "@reduxjs/toolkit";
import displayOffersReducer from "./slices/displayOffers";
import usersReducer from "./slices/users";
import websiteVisitReducer from "./slices/websiteVisits";
import customTasksReducer from "./slices/customTasks";
import notificationsReducer from "./slices/notifications";
import documentsReducer from "./slices/douments";

const reducer = {
  displayOffers: displayOffersReducer,
  users: usersReducer,
  websiteVisits: websiteVisitReducer,
  customTasks: customTasksReducer,
  notifications: notificationsReducer,
  documents: documentsReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
