import { styled, CircularProgress } from "@mui/material";

export const Loading = styled(CircularProgress)`
  margin: 0 50%;
`;

export const InternalLoading = styled(CircularProgress)(() => ({
  margin: "3rem auto",
}));

export default Loading;
