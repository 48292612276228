import { createContext, useContext, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import UserDataService from "../services/usersService";
import { red } from "@mui/material/colors";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userid") || "";
  const autoLoginCode = searchParams.get("autologincode") || "";

  useMemo(() => {
    if (user !== null && new Date(user.expiration) < new Date()) {
      setUser(null);
    }
  }, [user]);

  useMemo(() => {
    if (userId && autoLoginCode) {
      UserDataService.autoLoginUser({
        websiteId: "0b92aa8d-5fc2-4627-8a4f-a6e1c47113c9",
        userId,
        autoLoginCode,
      })
        .then((res) => {
          doLogin(res.data.data, true);
        })
        .catch(() => {
          logout();
        });
    }
  }, [userId, autoLoginCode]);

  const doLogin = async (data, redirect) => {
    if (data) setUser(data);

    if (redirect) navigate("/stations", { replace: true });
  };

  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      doLogin,
      logout,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
