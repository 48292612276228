import { Suspense, lazy } from "react";
import { Layout } from "./components/layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./navigation/ProtectedRoute";
import { AuthProvider } from "./navigation/Authentication";
import WebsiteVisitTracking from "./components/misc/WebsiteVisitTracking";
import NotificationCentre from "./components/misc/NotificationCentre";
import Loading from "./components/loading/Loading";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Home = lazy(() => import("./components/home"));
const Documents = lazy(() => import("./components/documents"));
const Register = lazy(() => import("./components/register"));
const Stations = lazy(() => import("./components/stations"));
const SelectedTask = lazy(() => import("./components/selected-task"));
const OfferRedirect = lazy(() => import("./components/offer-redirect"));
const Login = lazy(() => import("./components/login"));
const MyAccount = lazy(() => import("./components/my-account"));
const WelcomeSurvey = lazy(() => import("./components/welcome-survey"));
const PaymentDetails = lazy(() => import("./components/payment-details"));

const MyCashback = lazy(() => import("./components/my-cashback"));

const App = () => {
  const theme = createTheme({
    typography: {
      fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Layout>
              <CssBaseline />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/about"
                  element={<Documents docType={"about"} />}
                />
                <Route
                  path="/affiliates"
                  element={<Documents docType={"affiliates"} />}
                />
                <Route path="/documents/:type" element={<Documents />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/stations" element={<Stations />} />
                <Route
                  path="/my-cashback"
                  element={
                    <ProtectedRoute>
                      <MyCashback />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/welcome-survey"
                  element={
                    <ProtectedRoute>
                      <WelcomeSurvey />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/stations/station-:station"
                  element={<Stations />}
                />
                <Route
                  path="/stations/station-:station/:url"
                  element={<SelectedTask />}
                />
                <Route
                  path="/stations/station-:station/:url/redirect"
                  element={
                    <ProtectedRoute>
                      <OfferRedirect />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/my-account"
                  element={
                    <ProtectedRoute>
                      <MyAccount />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/payment-details"
                  element={
                    <ProtectedRoute>
                      <PaymentDetails />
                    </ProtectedRoute>
                  }
                />
              </Routes>

              <WebsiteVisitTracking />
              <NotificationCentre />
            </Layout>
          </AuthProvider>
        </ThemeProvider>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
