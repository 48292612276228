import {
  createSlice,
  createAsyncThunk,
  StateFromReducersMapObject,
} from "@reduxjs/toolkit";
import WebsiteVisitDataService from "../services/websiteVisitService";
export const initialState = {
  visitId: null,
};

export const trackWebsiteVisit = createAsyncThunk(
  "websitevisits/track",
  async (visit) => {
    const res = await WebsiteVisitDataService.trackVisit(visit);

    return res.data;
  }
);

const websiteVisitsSlice = createSlice({
  name: "websitevisits",
  initialState,
  extraReducers: (builder) =>
    builder
      .addCase(trackWebsiteVisit.fulfilled, (state: any, action: any) => {
        return { state, visitId: action.payload.visitId };
      })
      .addDefaultCase((state: any, _) => {
        return state;
      }),
  reducers: {},
});

const { reducer } = websiteVisitsSlice;

export default reducer;
