import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import usersService from "../services/usersService";

interface customTasks$State {
  saved: boolean | null;
}

export const initialState: customTasks$State = {
  saved: false,
};

export const saveWelcomeSurvey = createAsyncThunk(
  "customtasks/saveWelcomeSurvey",
  async ({ user, answers, clickid }: any) => {
    const res = await usersService.saveWelcomeSurvey(user, answers, clickid);
    return res.data;
  }
);

const customTasksSlice = createSlice({
  name: "customtasks",
  initialState,
  extraReducers: (builder) =>
    builder
      .addCase(saveWelcomeSurvey.fulfilled, (state) => {
        return (state = {
          saved: true,
        });
      })
      .addCase(saveWelcomeSurvey.rejected, (state) => {
        return (state = {
          saved: null,
        });
      }),
  reducers: {},
});

const { reducer } = customTasksSlice;

export default reducer;
