// All user related database operations can be defined here.

import { TRACK_WEBSITE_VISIT } from "./CONSTANTS";
import http from "../http-common";

class WebsiteVisitDataService {
  trackVisit(visitInfo: any) {
    return http
      .post(TRACK_WEBSITE_VISIT, visitInfo)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}

export default new WebsiteVisitDataService();
