/**
 * All API urls and other constants will reside here.
 * It is always a good idea to keep a local copy of all API response to
 * keep your app working for UI changes and
 * make it independent of network requirements.
 *
 * They need to be categorised and grouped together as:
 *  - Actual endpoints url.
 *  - Local data .json file path.
 * At a moment only one group should be uncommented.
 *
 * Other way to deal with this is to name every json file as per your service endpoint and use a basepath variable.
 * Toggle this basePath variable between "actual-domain.com/" or "/data/".
 */

// Actual endpoints. Uncomment below section to use actual data.
export const GET_ALL_DISPLAY_OFFERS = `/displayoffers/`;
export const GET_DISPLAY_OFFER_BY_URL = (url: string) =>
  `/displayoffers/url/${url}`;
export const GET_CASHBACK_OFFERS = (station: number) =>
  `/displayoffers/cashback?station=${station}`;
export const CREATE_USER = `/auth/register`;
export const UPDATE_USER = "/auth/update";

export const GET_USER = `/cashback/user`;
export const GET_USER_OFFERS = (station: number) =>
  `/cashback/user/offers/${station}`;
export const LOGIN_USER = `/auth/login`;
export const AUTO_LOGIN_USER = `/auth/auto-login`;
export const GET_USER_STATUS = `/cashback/user/status`;
export const SET_EMAIL_SUBSCRIPTION = "auth/set-email-subscription";
export const TRACK_DISPLAY_OFFER_CLICK = (url: string) =>
  `/displayoffers/url/${url}/track`;
export const TRACK_WEBSITE_VISIT = `/websitevisits/track`;
export const SAVE_WELCOME_SURVEY = `/cashback/user/save-welcome-survey/`;
export const GET_PAYMENT_DETAILS = "cashback/user/payment-details";
export const UPDATE_PAYMENT_DETAILS = "cashback/user/payment-details";
export const GET_NOTIFICATION = "notifications";
export const GET_DOCUMENT = (type: string) => `cashback/documents/${type}`;
//export const GET_USER_DETAILS = (id) => `https://jsonplaceholder.typicode.com/users/${id}`;

// Local endpoints. Uncomment below section to use dummy local data.
/*
export const GET_ALL_USERS = () => `/data/users`;
export const GET_USER_DETAILS = (id) => `/data/user`;
*/
