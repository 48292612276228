import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { trackWebsiteVisit } from "../../../slices/websiteVisits";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useSessionStorage } from "../../../navigation/useSessionStorage";

const WebsiteVisitTracking = (props: any) => {
  const [searchParams] = useSearchParams();
  const [visitId, setVisitId] = useSessionStorage("visitIdentifier", null);
  const { trackWebsiteVisit, websiteVisitIdentifier } = props;
  const visitTracked = useRef(false);

  useEffect(() => {
    if (visitTracked.current || (visitId !== undefined && visitId !== null))
      return;

    visitTracked.current = true;

    trackWebsiteVisit({
      affiliateId: searchParams.get("aff_id") || "DIRECT",
      affiliateSubId: searchParams.get("aff_sub") || "",
      platformTransactionId: searchParams.get("aff_sub2") || "",
      affiliateTransactionId: searchParams.get("aff_sub3") || "",
      websiteId: "0b92aa8d-5fc2-4627-8a4f-a6e1c47113c9",
      tag1: searchParams.get("t1"),
      tag2: searchParams.get("t2"),
      tag3: searchParams.get("t3"),
      tag4: searchParams.get("t4"),
      tag5: searchParams.get("t5"),
    });
  }, [searchParams, trackWebsiteVisit]);

  useEffect(() => {
    if (websiteVisitIdentifier !== null) {
      setVisitId(websiteVisitIdentifier);
    }
  }, [websiteVisitIdentifier, setVisitId]);

  return <></>;
};

WebsiteVisitTracking.propTypes = {
  trackWebsiteVisit: PropTypes.func,
};

const mapStateToProps = (state: any) => {
  return {
    websiteVisitIdentifier: state.websiteVisits.visitId,
  };
};

export default connect(mapStateToProps, {
  trackWebsiteVisit,
})(WebsiteVisitTracking);
