import PropTypes from "prop-types";
import logoWhite from "../../assets/images/logo-white.webp";
import { styled, Grid, Container } from "@mui/material";
import { HeaderMenu } from "./HeaderMenu";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <HeaderContainer container>
      <Container>
        <Grid
          container
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={8}>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
            >
              <Logo
                src={logoWhite}
                width="300"
                height="54"
                alt="Cashback Train"
              />
            </a>
          </Grid>
          <Grid item xs={4}>
            <HeaderMenu />
          </Grid>
        </Grid>
      </Container>
    </HeaderContainer>
  );
};

const HeaderContainer = styled(Grid)`
  background: #07153a;
  width: 100%;
  background-size: cover;
  padding: 2rem 0;
  text-align: left;

  @media (min-width: 1024px) {
    justify-content: center;
  }

  @media (max-width: 787px) {
    padding: 1rem 0;
  }
`;

const Logo = styled("img")`
  display: block;
  max-width: 80%;

  @media (max-width: 700px) {
    height: auto;
  }
`;

Header.propTypes = {
  pageName: PropTypes.string,
};

export default Header;
