// All user related database operations can be defined here.

import {
  CREATE_USER,
  UPDATE_USER,
  GET_USER,
  GET_USER_STATUS,
  LOGIN_USER,
  SET_EMAIL_SUBSCRIPTION,
  SAVE_WELCOME_SURVEY,
  GET_PAYMENT_DETAILS,
  UPDATE_PAYMENT_DETAILS,
  AUTO_LOGIN_USER,
  GET_USER_OFFERS,
} from "./CONSTANTS";
import http from "../http-common";

export interface LoginModel {
  username: string;
  password: string;
  websiteId: string;
}

export interface AutoLoginModel {
  userId: string;
  autoLoginCode: string;
  websiteId: string;
}

export interface SubscriptionRequestModel {
  optin: boolean;
}

class UsersDataService {
  createUser(user: any) {
    return http
      .post(CREATE_USER, user)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  updateUser(user: any, details: any) {
    return http
      .post(UPDATE_USER, details, {
        headers: {
          Authorization: user.type + " " + user.token,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  getUser(user: any) {
    return http.get(GET_USER, {
      headers: {
        Authorization: user.type + " " + user.token,
      },
    });
  }

  getUserStatus(user: any) {
    return http.get(GET_USER_STATUS, {
      headers: {
        Authorization: user.type + " " + user.token,
      },
    });
  }

  getUserOffers(user: any, station: number) {
    return http.get(GET_USER_OFFERS(station), {
      headers: {
        Authorization: user.type + " " + user.token,
      },
    });
  }

  loginUser(loginRequest: LoginModel) {
    return http.post(LOGIN_USER, loginRequest);
  }

  autoLoginUser(loginRequest: AutoLoginModel) {
    return http.post(AUTO_LOGIN_USER, loginRequest);
  }

  setUserSubscription(
    user: any,
    emailSubscriptionRequest: SubscriptionRequestModel
  ) {
    return http.post(SET_EMAIL_SUBSCRIPTION, emailSubscriptionRequest, {
      headers: {
        Authorization: user.type + " " + user.token,
      },
    });
  }

  saveWelcomeSurvey(user: any, answers: any, clickid: string) {
    return http.post(
      SAVE_WELCOME_SURVEY,
      { answers, clickid },
      {
        headers: {
          Authorization: user.type + " " + user.token,
        },
      }
    );
  }

  getPaymentDetails(user: any) {
    return http.get(GET_PAYMENT_DETAILS, {
      headers: {
        Authorization: user.type + " " + user.token,
      },
    });
  }

  updatePaymentDetails(user: any, details: any) {
    return http.post(UPDATE_PAYMENT_DETAILS, details, {
      headers: {
        Authorization: user.type + " " + user.token,
      },
    });
  }
}

export default new UsersDataService();
