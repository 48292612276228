import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserDataService, { LoginModel } from "../services/usersService";

export interface users$State {
  userLoggedIn: boolean;
  duplicateAccount: boolean;
  user: string | null;
  userId: string | null;
  cashbackUser: any | null;
  loginAttemptFailed: boolean;
  paymentDetails: any;
  paymentDetailsUpdated: boolean;
  offers: any;
  profileUpdated: boolean;
}

export const initialState: users$State = {
  userLoggedIn: false,
  duplicateAccount: false,
  user: null,
  userId: null,
  cashbackUser: null,
  loginAttemptFailed: false,
  paymentDetails: null,
  paymentDetailsUpdated: false,
  offers: null,
  profileUpdated: false,
};

export const createUser = createAsyncThunk("users/create", async (user) => {
  const res = await UserDataService.createUser(user);
  return res.data;
});

export const getUser = createAsyncThunk("users/get", async (user) => {
  const res = await UserDataService.getUser(user);
  return res.data;
});

export const getPaymentDetails = createAsyncThunk(
  "users/payment-details",
  async (user) => {
    const res = await UserDataService.getPaymentDetails(user);
    return res.data;
  }
);

export const updatePaymentDetails = createAsyncThunk(
  "users/update-payment-details",
  async ({ user, details }: any) => {
    const res = await UserDataService.updatePaymentDetails(user, details);
    return res.data;
  }
);

export const updateUser = createAsyncThunk(
  "users/update",
  async ({ user, details }: any) => {
    const res = await UserDataService.updateUser(user, details);
    return res.data;
  }
);

export const getUserStatus = createAsyncThunk("users/status", async (user) => {
  const res = await UserDataService.getUserStatus(user);
  return res.data;
});

export const getUserOffers = createAsyncThunk(
  "users/offers",
  async ({ user, station }: any) => {
    const res = await UserDataService.getUserOffers(user, station);

    return res.data;
  }
);

export const loginUser = createAsyncThunk(
  "users/login",
  async (payload: LoginModel) => {
    const res = await UserDataService.loginUser(payload);
    return res.data;
  }
);

export const setEmailSubscription = createAsyncThunk(
  "users/set-email-subscription",
  async ({ user, payload }: any) => {
    const res = await UserDataService.setUserSubscription(user, payload);
    return res.data;
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) =>
    builder
      .addCase(createUser.fulfilled, (state: any, action: any) => {
        let dupAccount: boolean = false;
        let userLoggedIn: boolean = false;
        let user: any = null;
        let userId: string | null = null;

        if (action.payload.success) {
          userLoggedIn = true;
          userId = "";
          user = action.payload;
        } else {
          if (
            action.payload.errors !== undefined &&
            action.payload.errors.length > 0
          ) {
            for (var i = 0; i < action.payload.errors.length; i++) {
              let error = action.payload.errors[i];

              if (
                error.propertyName === "Email" &&
                error.errorMessage === "Email Already Exists."
              ) {
                dupAccount = true;
                userLoggedIn = false;
              }
            }
          }
        }

        return (state = {
          duplicateAccount: dupAccount,
          userLoggedIn: userLoggedIn,
          user: user,
          userId: userId,
          cashbackUser: state.cashbackUser,
          status: state.status,
          loginAttemptFailed: false,
          paymentDetails: null,
          paymentDetailsUpdated: false,
          offers: null,
          profileUpdated: false,
        });
      })
      .addCase(createUser.rejected, (state: any) => state)
      .addCase(getUser.fulfilled, (state: any, action: any) =>
        Object.assign({}, state, { cashbackUser: action.payload })
      )
      .addCase(getUserStatus.fulfilled, (state: any, action: any) =>
        Object.assign({}, state, { status: action.payload })
      )
      .addCase(loginUser.fulfilled, (state: any) =>
        Object.assign({}, state, { loginAttemptFailed: false })
      )
      .addCase(loginUser.rejected, (state: any) =>
        Object.assign({}, state, { loginAttemptFailed: true })
      )
      .addCase(setEmailSubscription.fulfilled, (state: any) => state)
      .addCase(getPaymentDetails.fulfilled, (state: any, action: any) =>
        Object.assign({}, state, { paymentDetails: action.payload })
      )
      .addCase(updatePaymentDetails.fulfilled, (state: any) =>
        Object.assign({}, state, { paymentDetailsUpdated: true })
      )
      .addCase(getUserOffers.fulfilled, (state: any, action: any) =>
        Object.assign({}, state, { offers: action.payload })
      )
      .addCase(updateUser.fulfilled, (state: any) =>
        Object.assign({}, state, { profileUpdated: true })
      )
      .addCase(updateUser.rejected, (state: any) =>
        Object.assign({}, state, { profileUpdated: false })
      ),
  reducers: {},
});

const { reducer } = usersSlice;

export default reducer;
