import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DisplayOffersService from "../services/displayOffersService";

interface displayOffers$State {
  destination: string | null;
  offers: any;
  offer: any;
  offerLoading: boolean;
}

export const initialState: displayOffers$State = {
  destination: null,
  offers: null,
  offer: null,
  offerLoading: false,
};

export const getDisplayOffers = createAsyncThunk(
  "displayoffers/getall",
  async () => {
    const res = await DisplayOffersService.getAll();
    return res.data;
  }
);

export const getDisplayOffer = createAsyncThunk(
  "displayoffers/get",
  async (url: string) => {
    const res = await DisplayOffersService.get(url);
    return res.data;
  }
);

export const filterDisplayOffers = createAsyncThunk(
  "displayoffers/filter",
  async (station: number) => {
    const res = await DisplayOffersService.getCashbackOffers(station);
    return res.data;
  }
);

export const trackClick = createAsyncThunk(
  "displayoffers/track",
  async ({ url, category, user, visitId }: any) => {
    const res = await DisplayOffersService.track(url, category, user, visitId);
    return res.data;
  }
);

const displayOffersSlice = createSlice({
  name: "displayoffers",
  initialState,
  extraReducers: (builder) =>
    builder
      .addCase(getDisplayOffers.fulfilled, (state, action) => {
        return (state = {
          destination: null,
          offers: action.payload,
          offer: state.offer,
          offerLoading: state.offerLoading,
        });
      })
      .addCase(getDisplayOffer.pending, (state, action) => {
        return (state = {
          destination: null,
          offers: state.offers,
          offer: state.offer,
          offerLoading: true,
        });
      })
      .addCase(getDisplayOffer.fulfilled, (state, action) => {
        return (state = {
          destination: null,
          offer: action.payload,
          offers: state.offers,
          offerLoading: false,
        });
      })
      .addCase(filterDisplayOffers.fulfilled, (state, action) => {
        return (state = {
          destination: null,
          offers: action.payload,
          offer: state.offer,
          offerLoading: state.offerLoading,
        });
      })
      .addCase(trackClick.fulfilled, (state, action) => {
        return (state = {
          destination: action.payload,
          offers: state.offers,
          offer: state.offer,
          offerLoading: state.offerLoading,
        });
      }),
  reducers: {},
});

const { reducer } = displayOffersSlice;

export default reducer;
