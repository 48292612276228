import axios from "axios";

declare var APP: any;

const axiosCall = axios.create({
  baseURL: APP.API_BASEURI,
  headers: {
    "Content-type": "application/json",
  },
});

// Add a response interceptor
axiosCall.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosCall.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosCall;
